import { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import Loader from '../../components/Loader';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isAuthenticated, isSignUpFinished, user } = useAuth();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
  const location = useLocation();

  console.log('<========= AuthGuard ================>');

  useEffect(() => {
    console.log('<<<>>> ', user?.roles?.includes('Admin'), user?.roles?.includes('Paid Member'));
    setIsAdmin(user?.roles?.includes('Admin'));
    console.log('isSignUpFinished isAdmin', !isAuthenticated, !user?.roles?.includes('Admin'));
    if (!isAuthenticated) {
      navigate('login', {
        state: {
          from: location.pathname
        },
        replace: true
      });
    } else if (isSignUpFinished !== undefined && isAuthenticated && user?.roles?.includes('Admin')) {
      // navigate('dashboard', { replace: false });
    }
  }, [isAuthenticated, navigate, isSignUpFinished, user, isAdmin, location]);

  if (isSignUpFinished !== undefined && user?.roles?.includes('Admin')) {
    return children;
  } else if (isSignUpFinished !== undefined && !user?.roles?.includes('Admin')) {
    return <></>;
  } else {
    return <Loader />;
  }
};

export default AuthGuard;
