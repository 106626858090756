import axios from 'axios';

export type PlatformStatus = {
  activeStatus: { value: string; color: string; textColor: string; url: string };
  activeIncidents: { value: string; color: string; textColor: string; url: string; title: string };
  activeMaintenances: { value: string; color: string; textColor: string; url: string; title: string };
};

export const ACTIVE_STATUS_MAP: any = {
  '': {
    value: 'DEFAULT',
    color: ''
  },
  DEFAULT: {
    value: 'DEFAULT',
    color: ''
  },
  UP: {
    value: 'UP',
    color: ''
  },
  HASISSUES: {
    value: 'Platform issue',
    color: '#EC8C19',
    textColor: '#FFF'
  },
  ALLMAJOROUTAGE: {
    value: 'Major platform outage',
    color: '#FE4304',
    textColor: '#FFF'
  },
  SOMEMAJOROUTAGE: {
    value: 'Major platform outage',
    color: '#FE4304',
    textColor: '#FFF'
  },
  ONEMAJOROUTAGE: {
    value: 'Major platform outage',
    color: '#FE4304',
    textColor: '#FFF'
  },
  ALLMINOROUTAGE: {
    value: 'Minor platform outage',
    color: '#EC8C19',
    textColor: '#FFF'
  },
  SOMEMINOROUTAGE: {
    value: 'Minor platform outage',
    color: '#F4CB16',
    textColor: '#000'
  },
  ONEMINOROUTAGE: {
    value: 'Minor platform outage',
    color: '#F4CB16',
    textColor: '#000'
  }
};

export const ACTIVE_INCIDENT_STATUS_MAP: any = {
  '': {
    value: 'DEFAULT',
    color: '',
    textColor: ''
  },
  DEFAULT: {
    value: 'DEFAULT',
    color: '',
    textColor: ''
  },
  INVESTIGATING: {
    value: 'Investigating',
    color: '#EC8C19',
    textColor: '#FFF'
  },
  IDENTIFIED: {
    value: 'Issue identified',
    color: '#EC8C19',
    textColor: '#FFF'
  },
  MONITORING: {
    value: 'Fix implemented',
    color: '#F4CB16',
    textColor: '#000'
  },
  RESOLVED: {
    value: 'Resolved',
    color: '#32B17E',
    textColor: '#FFF'
  }
};

export const ACTIVE_INCIDENT_COLOR_MAP: any = {
  '': {
    color: '',
    textColor: ''
  },
  DEFAULT: {
    color: '',
    textColor: ''
  },
  UNDERMAINTENANCE: {
    color: '#555555',
    textColor: '#FFF'
  },
  OPERATIONAL: {
    color: '#32B17E',
    textColor: '#FFF'
  },
  DEGRADEDPERFORMANCE: {
    color: '#F4CB16',
    textColor: '#000'
  },
  PARTIALOUTAGE: {
    color: '#EC8C19',
    textColor: '#FFF'
  },
  MAJOROUTAGE: {
    color: '#FE4304',
    textColor: '#FFF'
  }
};

export const ACTIVE_MAINTENANCE_STATUS_MAP: any = {
  '': {
    value: 'DEFAULT',
    color: '',
    textColor: ''
  },
  DEFAULT: {
    value: 'DEFAULT',
    color: '',
    textColor: ''
  },
  NOTSTARTEDYET: {
    value: 'Upcoming',
    color: '#F4CB16',
    textColor: '#000'
  },
  INPROGRESS: {
    value: 'In progress',
    color: '#555555',
    textColor: '#FFF'
  },
  COMPLETED: {
    value: 'Completed',
    color: '#32B17E',
    textColor: '#FFF'
  }
};

export class PlatformStatusService {
  getStatus = async (): Promise<PlatformStatus> => {
    let activeStatus = ACTIVE_STATUS_MAP.DEFAULT;
    let activeIncidents = ACTIVE_INCIDENT_STATUS_MAP.DEFAULT;
    let activeMaintenances = ACTIVE_MAINTENANCE_STATUS_MAP.DEFAULT;
    let statusUrl = '';
    let incidentUrl = '';
    let maintenanceUrl = '';
    let incidentTitle = null;
    let maintenanceTitle = null;
    const response = await axios.get('https://traders-connect.instatus.com/summary.json');
    if (!!response?.data && !!response?.data?.page?.status) {
      const status = response?.data?.page?.status;
      if (ACTIVE_STATUS_MAP[status]) {
        activeStatus = ACTIVE_STATUS_MAP[status ? status : ''];
        statusUrl = response?.data?.page?.url;
      }

      const statusIncident = response?.data?.activeIncidents?.length > 0 ? response?.data?.activeIncidents[0]?.status : null;
      incidentTitle = response?.data?.activeIncidents?.length > 0 ? response?.data?.activeIncidents[0]?.name : null;
      const statusIncidentImpact = response?.data?.activeIncidents?.length > 0 ? response?.data?.activeIncidents[0]?.impact : null;
      console.log('statusIncident: ', response, statusIncident, ACTIVE_INCIDENT_STATUS_MAP[statusIncident]);
      if (ACTIVE_INCIDENT_STATUS_MAP[statusIncident] && ACTIVE_INCIDENT_COLOR_MAP[statusIncidentImpact]) {
        activeIncidents = ACTIVE_INCIDENT_STATUS_MAP[statusIncident ? statusIncident : ''];
        const activeIncidentsColors = ACTIVE_INCIDENT_COLOR_MAP[statusIncidentImpact ? statusIncidentImpact : ''];
        activeIncidents = {
          ...activeIncidents,
          ...activeIncidentsColors
        };
        incidentUrl = response?.data?.activeIncidents[0]?.url;
      }

      const statusMaintenance = response?.data?.activeMaintenances?.length > 0 ? response?.data?.activeMaintenances[0]?.status : null;
      maintenanceTitle = response?.data?.activeMaintenances?.length > 0 ? response?.data?.activeMaintenances[0]?.name : null;
      // console.log('statusMaintenance: ', response, statusMaintenance, ACTIVE_MAINTENANCE_STATUS_MAP[statusMaintenance]);
      if (ACTIVE_MAINTENANCE_STATUS_MAP[statusMaintenance]) {
        activeMaintenances = ACTIVE_MAINTENANCE_STATUS_MAP[statusMaintenance ? statusMaintenance : ''];
        maintenanceUrl = response?.data?.activeMaintenances[0]?.url;
      }
    }
    return {
      activeStatus: {
        ...activeStatus,
        url: statusUrl
      },
      activeIncidents: {
        ...activeIncidents,
        url: incidentUrl,
        title: incidentTitle
      },
      activeMaintenances: {
        ...activeMaintenances,
        url: maintenanceUrl,
        title: maintenanceTitle
      }
    };
  };
}
