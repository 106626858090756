import { NavItemType } from '../types/menu';
import { FormattedMessage } from 'react-intl';
import {
  CloudServerOutlined,
  DashboardOutlined,
  FileTextOutlined,
  LineChartOutlined,
  UsergroupAddOutlined,
  RetweetOutlined
} from '@ant-design/icons';

const allNavItem: NavItemType = {
  id: 'default-group',
  // title: <FormattedMessage id="default-group" />,
  type: 'group',
  breadcrumbs: false,
  children: [
    {
      icon: DashboardOutlined,
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      breadcrumbs: false
    },
    {
      icon: CloudServerOutlined,
      id: 'brokers',
      title: <FormattedMessage id="brokers" />,
      type: 'item',
      url: '/brokers',
      breadcrumbs: false
    },
    {
      icon: UsergroupAddOutlined,
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/users',
      breadcrumbs: false
    },
    {
      id: 'accounts',
      title: <FormattedMessage id="accounts" />,
      icon: LineChartOutlined,
      type: 'item',
      breadcrumbs: false,
      url: '/accounts'
    },
    {
      id: 'trade-copiers',
      title: <FormattedMessage id="trade-copiers" />,
      icon: FileTextOutlined,
      type: 'item',
      breadcrumbs: false,
      url: '/trade-copiers'
    },
    {
      id: 'symbol-management',
      title: <FormattedMessage id="symbol-management" />,
      icon: RetweetOutlined,
      type: 'item',
      breadcrumbs: false,
      url: '/symbol-management'
    }
    /*{
      id: 'accounts',
      title: <FormattedMessage id="accounts" />,
      icon: LineChartOutlined,
      type: 'item',
      breadcrumbs: false,
      url: '/accounts'
      /!*children: [
        {
          id: 'account-analytics',
          title: <FormattedMessage id="analytics" />,
          icon: LineChartOutlined,
          type: 'item',
          breadcrumbs: true,
          url: '/accounts/analytics/:id'
        }
      ]*!/
    },
    {
      id: 'copy-trading',
      title: <FormattedMessage id="copy-trading" />,
      icon: FileTextOutlined,
      type: 'item',
      breadcrumbs: false,
      url: '/copy-trading'
    },
    {
      id: 'economic-calendar',
      title: <FormattedMessage id="economic-calendar" />,
      icon: CalendarOutlined,
      type: 'item',
      breadcrumbs: false,
      url: '/economic-calendar'
    },
    /!*{
      id: 'copy-trading-group',
      title: <FormattedMessage id="copy-trading" />,
      type: 'collapse',
      breadcrumbs: false,
      icon: FileTextOutlined,
      url: '/copy-trading',
      children: [

      ]
    },*!/
    {
      icon: DeploymentUnitOutlined,
      id: 'integrations',
      title: <FormattedMessage id="integrations" />,
      type: 'item',
      url: '/integration',
      breadcrumbs: false
    }*/
  ]
};

export default allNavItem;
