import { openSnackbar } from '../../store/reducers/snackbar';

export const getStripeErrorMessage = (code?: string) => {
  const msz = stripeErrors[code || ''];
  if (msz) {
    return msz;
  }

  return `Stripe error code: ${code}`;
};

export const stripeErrors: any = {
  payment_intent_authentication_failure: 'Failed to authenticate payment, please try again',
  card_declined: 'Payment declined, please try again',
  expired_card: 'Card expired, please try another card',
  incorrect_cvc: 'Incorrect CVC, please try again',
  processing_error: 'Payment processing error, please try again',
  incorrect_number: 'Invalid card number, re-enter card number',
  incorrect_zip: 'Payment declined, incorrect ZIP code',
  '': '',
  null: ''
};

export class DispatchSnackbarService {
  dispatchSnackbar = (
    dispatch: any,
    message: string,
    variant: 'default' | 'alert',
    color: 'success' | 'info' | 'warning' | 'error' | 'primary' | 'secondary' | undefined,
    duration: number
  ) => {
    dispatch(
      openSnackbar({
        open: true,
        message: message,
        duration: duration,
        variant: variant,
        alert: {
          color: color
        }
      })
    );
  };
}
