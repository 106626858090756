import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
// import useAuth from '../../../../../hooks/useAuth';
// import { useEffect, useState } from 'react';
// import { cloneDeep } from 'lodash';
// import { CalendarOutlined, DollarCircleOutlined } from '@ant-design/icons';
// import { FormattedMessage } from 'react-intl';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  // const { user } = useAuth();
  const { drawerOpen } = menu;
  // const [allMenuItems, setAllMenuItems] = useState(menuItem);

  /*useEffect(() => {
    const tmpMenu = cloneDeep(menuItem);
    if (user?.isBetaUser) {
      const calendarItem = {
        icon: CalendarOutlined,
        id: 'calendar',
        title: <FormattedMessage id="economic-calendar" />,
        type: 'item',
        url: '/economic-calendar',
        breadcrumbs: false
      };
      const items: any[] = tmpMenu?.items[0]?.children || [];
      if (tmpMenu.items[0] && tmpMenu.items[0].children) {
        tmpMenu.items[0].children = [...items.slice(0, 3), calendarItem, ...items.slice(3, items.length)];
        console.log('tmpMenu.items[0].children: ', tmpMenu.items[0].children);
      }
      // tmpMenu?.items[0]?.children?.push(calendarItem);
      setAllMenuItems(tmpMenu);
    }
    const isFree = user?.roles?.includes('Free Member');
    if (isFree) {
      const pricingItem = {
        icon: DollarCircleOutlined,
        id: 'pricing',
        title: <FormattedMessage id="pricing" />,
        type: 'item',
        url: '/pricing',
        breadcrumbs: false
      };
      tmpMenu?.items[0]?.children?.push(pricingItem);
      setAllMenuItems(tmpMenu);
    }
  }, [user]);*/

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
