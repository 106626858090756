// types
import { DefaultConfigProps } from 'types/config';

export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

/*export const AUTH0_API = {
  client_id: 'V24lwXS8yYwTITifQRvLSKMI5zV4iPx1',
  domain: 'dev-70m0h-cu.us.auth0.com',
  connection: 'Username-Password-Authentication',
  namespace: 'https://tradersconnect.io',
  audience: 'http://localhost:10000',
  audienceURL: 'https://dev-70m0h-cu.us.auth0.com/api/v2/'
};*/

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  pricing: '/pricing',
  defaultPath: '/dashboard',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: true,
  container: false,
  mode: 'light',
  presetColor: 'theme8',
  themeDirection: 'ltr'
};

export default config;
