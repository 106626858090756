import { createSlice } from '@reduxjs/toolkit';

// third-party
import { EventInput } from '@fullcalendar/common';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';

// types
import { CalendarProps } from 'types/calendar';
import { add, set, sub } from 'date-fns';
import { EVENT_COLORS } from '../../data/calendar';

const initialState: CalendarProps = {
  calendarView: 'listWeek',
  error: false,
  events: [],
  isLoader: false,
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

// ==============================|| CALENDAR - SLICE ||============================== //

const calendar = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // loader
    loading(state) {
      state.isLoader = true;
    },

    // error
    hasError(state, action) {
      state.isLoader = false;
      state.error = action.payload;
    },

    // event list
    setEvents(state, action) {
      state.isLoader = false;
      state.events = action.payload;
    },

    // update calendar view
    updateCalendarView(state, action) {
      state.calendarView = action.payload;
    },

    // select event
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },

    // create event
    createEvent(state, action) {
      const newEvent = action.payload;
      state.isLoader = false;
      state.isModalOpen = false;
      state.events = [...state.events, newEvent];
    },

    // update event
    updateEvent(state, action) {
      const event = action.payload;
      const eventUpdate = state.events.map((item) => {
        if (item.id === event.id) {
          return event;
        }
        return item;
      });

      state.isLoader = false;
      state.isModalOpen = false;
      state.events = eventUpdate;
    },

    // delete event
    deleteEvent(state, action) {
      const { eventId } = action.payload;
      state.isModalOpen = false;
      const deleteEvent = state.events.filter((user) => user.id !== eventId);
      state.events = deleteEvent;
    },

    // select date range
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isModalOpen = true;
      state.selectedRange = { start, end };
    },

    // modal toggle
    toggleModal(state) {
      state.isModalOpen = !state.isModalOpen;
      if (state.isModalOpen === false) {
        state.selectedEventId = null;
        state.selectedRange = null;
      }
    }
  }
});

export default calendar.reducer;

export const { selectEvent, toggleModal, updateCalendarView } = calendar.actions;

export function getEvents() {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      const response = [
        {
          id: '5e8882f1f0c9216397e05a9b',
          allDay: false,
          color: EVENT_COLORS[6],
          description: 'SCRUM Planning',
          start: sub(new Date(), { days: 12, hours: 5, minutes: 45 }),
          end: sub(new Date(), { days: 12, hours: 3, minutes: 30 }),
          title: 'Repeating Event'
        },
        {
          id: '5e8882fcd525e076b3c1542c',
          allDay: true,
          color: EVENT_COLORS[2],
          description: 'Sorry, John!',
          start: sub(new Date(), { days: 8, hours: 0, minutes: 45 }),
          end: sub(new Date(), { days: 8, hours: 0, minutes: 30 }),
          title: 'Conference'
        },
        {
          id: '5e8882e440f6322fa399eeb8',
          allDay: true,
          color: EVENT_COLORS[4],
          description: 'Inform about new contract',
          start: sub(new Date(), { days: 3 }),
          end: sub(new Date(), { days: 4 }),
          title: 'All Day Event'
        },
        {
          id: '5e8882fcd525e076b3c1542c',
          allDay: false,
          color: EVENT_COLORS[3],
          textColor: EVENT_COLORS[4],
          description: 'Sorry, Stebin Ben!',
          start: sub(new Date(), { days: 2, hours: 5, minutes: 0 }),
          end: sub(new Date(), { days: 2, hours: 1, minutes: 30 }),
          title: 'Opening Ceremony'
        },
        {
          id: '5e8882eb5f8ec686220ff131',
          allDay: true,
          color: EVENT_COLORS[0],
          description: 'Discuss about new partnership',
          start: sub(new Date(), { days: 4, hours: 0, minutes: 0 }),
          end: sub(new Date(), { days: 2, hours: 1, minutes: 0 }),
          title: 'Long Event'
        },
        {
          id: '5e88830672d089c53c46ece3',
          allDay: false,
          description: 'Get a new quote for the payment processor',
          start: set(new Date(), { hours: 6, minutes: 30 }),
          end: set(new Date(), { hours: 8, minutes: 30 }),
          title: 'Breakfast'
        },
        {
          id: '5e888302e62149e4b49aa609',
          allDay: false,
          color: EVENT_COLORS[1],
          textColor: EVENT_COLORS[2],
          description: 'Discuss about the new project',
          start: add(new Date(), { hours: 9, minutes: 45 }),
          end: add(new Date(), { hours: 15, minutes: 30 }),
          title: 'Meeting'
        },
        {
          id: '5e888302e62149e4b49aa709',
          allDay: false,
          color: EVENT_COLORS[6],
          description: "Let's Go",
          start: add(new Date(), { hours: 9, minutes: 0 }),
          end: add(new Date(), { hours: 11, minutes: 30 }),
          title: 'Anniversary Celebration'
        },
        {
          id: '5e888302e69651e4b49aa609',
          allDay: false,
          description: 'Discuss about the new project',
          start: add(new Date(), { days: 1, hours: 5, minutes: 25 }),
          end: add(new Date(), { days: 1, hours: 5, minutes: 55 }),
          title: 'Send Gift'
        },
        {
          id: '5e8883062k8149e4b49aa709',
          allDay: false,
          color: EVENT_COLORS[2],
          description: "Let's Go",
          start: add(new Date(), { days: 1, hours: 3, minutes: 45 }),
          end: add(new Date(), { days: 1, hours: 5, minutes: 15 }),
          title: 'Birthday Party'
        },
        {
          id: '5e8882f1f0c9216396e05a9b',
          allDay: false,
          color: EVENT_COLORS[0],
          description: 'SCRUM Planning',
          start: add(new Date(), { days: 1, hours: 3, minutes: 30 }),
          end: add(new Date(), { days: 1, hours: 4, minutes: 30 }),
          title: 'Repeating Event'
        },
        {
          id: '5e888302e62149e4b49aa610',
          allDay: false,
          color: EVENT_COLORS[6],
          description: "Let's Go",
          start: add(new Date(), { days: 1, hours: 3, minutes: 45 }),
          end: add(new Date(), { days: 1, hours: 4, minutes: 50 }),
          title: 'Dinner'
        },
        {
          id: '5e8882eb5f8ec686220ff131',
          allDay: true,
          description: 'Discuss about new partnership',
          start: add(new Date(), { days: 5, hours: 0, minutes: 0 }),
          end: add(new Date(), { days: 8, hours: 1, minutes: 0 }),
          title: 'Long Event'
        },
        {
          id: '5e888302e62349e4b49aa609',
          allDay: false,
          color: EVENT_COLORS[5],
          textColor: EVENT_COLORS[7],
          description: 'Discuss about the project launch',
          start: add(new Date(), { days: 6, hours: 0, minutes: 15 }),
          end: add(new Date(), { days: 6, hours: 0, minutes: 20 }),
          title: 'Meeting'
        },
        {
          id: '5e888302e62149e4b49ab609',
          allDay: false,
          color: EVENT_COLORS[4],
          description: 'Discuss about the tour',
          start: add(new Date(), { days: 12, hours: 3, minutes: 45 }),
          end: add(new Date(), { days: 12, hours: 4, minutes: 50 }),
          title: 'Happy Hour'
        }
      ];
      console.log('CALENDER RESPONSE', response);
      dispatch(calendar.actions.setEvents(response));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function createEvent(newEvent: Omit<EventInput, 'id'>) {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      const response = await axios.post('/api/calendar/events/add', newEvent);
      dispatch(calendar.actions.createEvent(response.data.event));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function updateEvent(
  eventId: string,
  updateEvent: Partial<{
    allDay: boolean;
    start: Date | null;
    end: Date | null;
  }>
) {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        update: updateEvent
      });
      dispatch(calendar.actions.updateEvent(response.data.event));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function deleteEvent(eventId: string) {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(calendar.actions.deleteEvent({ eventId }));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

export function selectRange(start: Date, end: Date) {
  return async () => {
    dispatch(
      calendar.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}
