// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Scrollbar(theme: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '::-webkit-scrollbar': {
          width: '8px'
        },
        '::-webkit-scrollbar-track': {
          borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? theme.palette.grey['300'] : theme.palette.grey['100'],
          borderRadius: '15px',
          height: '2px'
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: theme.palette.mode === 'light' ? theme.palette.grey['500'] : theme.palette.grey['200'],
          maxHeight: '10px'
        },
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable'
        /*root: {
          '&.Mui-disabled': {
            '.MuiSlider-rail': {
              opacity: 0.25
            },
            '.MuiSlider-track': {
              color: theme.palette.secondary.lighter
            },
            '.MuiSlider-thumb': {
              border: `2px solid ${theme.palette.secondary.lighter}`
            }
          }
        }*/
      }
    }
  };
}
