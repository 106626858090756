// material-ui
import { List } from '@mui/material';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  /*const { pathname } = useLocation();
  useEffect(() => {
    setSelectedIndex(getPathIndex(pathname));
  }, [pathname]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    console.log('INDEX ------> ', index);
    setSelectedIndex(index);
    if (index === 1) {
      navigate('account-settings/change-password', { replace: false });
    } else if (index === 0) {
      navigate('account-settings/personal', { replace: false });
    } else if (index === 2) {
      navigate('account-settings/billing', { replace: false });
    }
  };*/

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/*<ListItemButton selected={selectedIndex === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Account Settings" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 2)}>
        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton>*/}
    </List>
  );
};

export default SettingTab;
