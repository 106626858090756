// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Grid } from '@mui/material';
import React from 'react';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  return (
    <>
      <Grid container justifyContent={'flex-start'} alignItems={'flex-start'}>
        <SimpleBar
          sx={{
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          <Navigation />
        </SimpleBar>
      </Grid>
    </>
  );
};

export default DrawerContent;
