// import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Chip, useMediaQuery } from '@mui/material';

import Profile from './Profile';
import MobileSection from './MobileSection';
import ThemeMode from './ThemeMode';
import { PlatformStatus, PlatformStatusService } from '../../../../services';
import { useEffect, useState } from 'react';
import useAuth from '../../../../hooks/useAuth';

// ==============================|| HEADER - CONTENT ||============================== //

const platformStatusService = new PlatformStatusService();
const HeaderContent = () => {
  // const { i18n } = useConfig();
  const [active, setActive] = useState<PlatformStatus>({
    activeStatus: {
      value: 'DEFAULT',
      color: '',
      textColor: '',
      url: ''
    },
    activeMaintenances: {
      value: 'DEFAULT',
      color: '',
      textColor: '',
      url: '',
      title: ''
    },
    activeIncidents: {
      value: 'DEFAULT',
      color: '',
      textColor: '',
      url: '',
      title: ''
    }
  });

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { user } = useAuth();

  useEffect(() => {
    platformStatusService
      .getStatus()
      .then((res) => {
        console.log(res);
        setActive(res);
      })
      .catch((err) => {
        console.log('ERROR: ', err);
        setActive({
          activeStatus: {
            value: 'DEFAULT',
            color: '',
            textColor: '',
            url: ''
          },
          activeMaintenances: {
            value: 'DEFAULT',
            color: '',
            textColor: '',
            url: '',
            title: ''
          },
          activeIncidents: {
            value: 'DEFAULT',
            color: '',
            textColor: '',
            url: '',
            title: ''
          }
        });
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      platformStatusService
        .getStatus()
        .then((res) => {
          console.log(res);
          setActive(res);
        })
        .catch((err) => {
          console.log('ERROR: ', err);
          setActive({
            activeStatus: {
              value: 'DEFAULT',
              color: '',
              textColor: '',
              url: ''
            },
            activeMaintenances: {
              value: 'DEFAULT',
              color: '',
              textColor: '',
              url: '',
              title: ''
            },
            activeIncidents: {
              value: 'DEFAULT',
              color: '',
              textColor: '',
              url: '',
              title: ''
            }
          });
        });
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <>
        {(user?.roles?.includes('Admin') || user?.roles?.includes('Paid Member')) &&
          active?.activeStatus?.value !== 'DEFAULT' &&
          active?.activeStatus?.value !== 'UP' &&
          active?.activeIncidents?.value === 'DEFAULT' && (
            <Chip
              sx={{ ml: 2, borderRadius: '12px' }}
              label={active?.activeStatus?.value}
              size="small"
              style={{ background: active?.activeStatus?.color, cursor: 'pointer', color: active?.activeStatus?.textColor }}
              onClick={() => window.open(active.activeStatus?.url)}
            />
          )}
        {(user?.roles?.includes('Admin') || user?.roles?.includes('Paid Member')) && active?.activeIncidents?.value !== 'DEFAULT' && (
          <Chip
            sx={{ ml: 2, borderRadius: '12px' }}
            label={
              active?.activeIncidents?.title
                ? active?.activeIncidents?.value + ': ' + active?.activeIncidents?.title
                : active?.activeIncidents?.value
            }
            size="small"
            style={{ background: active?.activeIncidents?.color, cursor: 'pointer', color: active?.activeIncidents?.textColor }}
            onClick={() => window.open(active.activeIncidents?.url)}
          />
        )}
        {(user?.roles?.includes('Admin') || user?.roles?.includes('Paid Member')) && active?.activeMaintenances?.value !== 'DEFAULT' && (
          <Chip
            sx={{ ml: 2, borderRadius: '12px' }}
            label={
              active?.activeMaintenances?.title
                ? active?.activeMaintenances?.value + ': ' + active?.activeMaintenances?.title
                : active?.activeMaintenances?.value
            }
            size="small"
            style={{ background: active?.activeMaintenances?.color, cursor: 'pointer', color: active?.activeMaintenances?.textColor }}
            onClick={() => window.open(active.activeMaintenances?.url)}
          />
        )}
      </>
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      <ThemeMode />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
