import React, { createContext, useEffect, useState } from 'react';

import { Auth0ContextType, FuLLAuth0UserProfile } from 'types/auth';
import { Auth0Service } from '../services';
import Loader from '../components/Loader';
import { config } from '../env';
const AUTH0_API = config.AUTH0_API;

// constant
const service = new Auth0Service();

const initialState: Auth0ContextType = {
  isInitialized: false,
  getAccessToken: () => {
    return Promise.resolve('');
  },
  refreshToken: () => {
    return Promise.resolve('');
  },
  login: service.login,
  signUp: service.signUp,
  logout: service.logout,
  accessToken: '',
  isLoading: true,
  setUser: (user) => {
    return {};
  },
  resetPassword: (email: string) => {
    return service.changePassword({ connection: AUTH0_API.connection, email });
  },
  forgotPassword: (email: string) => {
    return service.forgotPassword({ connection: AUTH0_API.connection, email });
  }
};

/*const initialAuth: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  token: null
};*/

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

export const Auth0Provider = ({ children }: { children: React.ReactElement }) => {
  // const [state, dispatch] = useReducer(authReducer, initialAuth);
  const [currentState, setCurrentState] = useState<Auth0ContextType>(initialState);
  const [user, setUser] = useState<FuLLAuth0UserProfile | undefined>();

  const refreshToken = () => {
    return new Promise((resolve, reject) => {
      service
        .checkSession()
        .then((token) => {
          setCurrentState({ ...currentState, isLoading: false, error: null, isAuthenticated: true, accessToken: token });
          service
            .getUserInfo(token)
            .then(async (userP) => {
              let user: FuLLAuth0UserProfile = userP;
              const metadata_key = AUTH0_API.namespace + '/user_metadata';
              const app_metadata_key = AUTH0_API.namespace + '/app_metadata';
              let key = AUTH0_API.namespace + '/firstName';

              user.firstName = (userP as any)[key] || userP.name;
              user.name =
                !!(userP as any)[metadata_key]?.firstName && !!(userP as any)[metadata_key]?.lastName
                  ? (userP as any)[metadata_key]?.firstName + ' ' + (userP as any)[metadata_key]?.lastName
                  : userP.name;
              user.tcId = (userP as any)[app_metadata_key]?.internal_user_id;
              user.accountLimit = (userP as any)[app_metadata_key]?.account_limit;
              user.currency = (userP as any)[metadata_key]?.currency;
              user.roles = (userP as any)[AUTH0_API.namespace + '/roles'] || [];
              user.isBetaUser = !!user?.roles?.find((e) => e === 'BETA');
              user.isSuperAdmin = !!user?.roles?.find((e) => e === 'Super Admin');
              const isSignUpFinished =
                !!(userP as any)[metadata_key]?.onboardingComplete && (userP as any)[metadata_key]?.onboardingComplete === 'TRUE';
              setUser(user);
              setCurrentState({
                ...currentState,
                isLoading: false,
                isInitialized: true,
                error: null,
                isAuthenticated: true,
                accessToken: token,
                isSignUpFinished: isSignUpFinished,
                refreshToken: refreshToken,
                user: userP
              });
              resolve(token);
            })
            .catch((er) => {
              reject(er);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    const init = () => {
      // setCurrentState({ ...currentState, isLoading: true, error: null, isAuthenticated: false, isInitialized: true });
      service
        .checkSession()
        .then((token) => {
          setCurrentState({
            ...currentState,
            isLoading: false,
            isInitialized: true,
            error: null,
            isAuthenticated: true,
            accessToken: token
          });
          service
            .getUserInfo(token)
            .then(async (userP) => {
              let user: FuLLAuth0UserProfile = userP;
              let metadata_key = AUTH0_API.namespace + '/user_metadata';
              let app_metadata_key = AUTH0_API.namespace + '/app_metadata';
              let key = AUTH0_API.namespace + '/firstName';

              user.firstName = (userP as any)[key] || userP.name;
              user.name =
                !!(userP as any)[metadata_key]?.firstName && !!(userP as any)[metadata_key]?.lastName
                  ? (userP as any)[metadata_key]?.firstName + ' ' + (userP as any)[metadata_key]?.lastName
                  : userP.name;
              user.tcId = (userP as any)[app_metadata_key]?.internal_user_id;
              user.accountLimit = (userP as any)[app_metadata_key]?.account_limit;
              user.currency = (userP as any)[metadata_key]?.currency;
              user.roles = (userP as any)[AUTH0_API.namespace + '/roles'] || [];
              user.isBetaUser = !!user?.roles?.find((e) => e === 'BETA');
              user.isSuperAdmin = !!user?.roles?.find((e) => e === 'Super Admin');
              setUser(user);
              const isSignUpFinished =
                !!(userP as any)[metadata_key]?.onboardingComplete && (userP as any)[metadata_key]?.onboardingComplete === 'TRUE';
              setCurrentState({
                ...currentState,
                isLoading: false,
                isInitialized: true,
                error: null,
                isAuthenticated: true,
                accessToken: token,
                isSignUpFinished: isSignUpFinished,
                refreshToken: refreshToken,
                user: user
              });
            })
            .catch((er) => {});
        })
        .catch((err) => {
          setCurrentState({ ...currentState, isLoading: false, isInitialized: true, error: err, isAuthenticated: false });
        });
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*useEffect(() => {
      const init = async () => {
        try {
          await auth0Client.checkSession();
          const isLoggedIn = await auth0Client.isAuthenticated();

          if (isLoggedIn) {
            const user = await auth0Client.getUser();

            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user: {
                  id: user?.sub,
                  email: user?.email
                }
              }
            });
          } else {
            dispatch({
              type: LOGOUT
            });
          }
        } catch (err) {
          dispatch({
            type: LOGOUT
          });
        }
      };

      init();
    }, []);*/

  /*const login = async (email: string, password: string) => {
    const result = await service.login(email, password);
    const userP = await service.getUserInfo(result.token);

    return result;
    /!*await auth0Client.loginWithPopup({});
    const isLoggedIn = await auth0Client.isAuthenticated();

    if (isLoggedIn) {
      const user = await auth0Client.getUser();
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            id: user?.sub,
            avatar: user?.picture,
            email: user?.email,
            name: user?.name,
            tier: 'Premium'
          }
        }
      });
    }*!/
  };*/

  /*const logout = async () => {
    await service.logout();

    dispatch({
      type: LOGOUT
    });
  };*/

  /*const resetPassword = async (email: string) => {};

  const updateProfile = () => {};*/

  if (currentState.isInitialized !== undefined && !currentState.isInitialized) {
    return <Loader />;
  }

  return (
    <Auth0Context.Provider
      value={{
        ...currentState,
        refreshToken,
        user: user,
        getAccessToken: () => Promise.resolve(currentState.accessToken)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Context;
