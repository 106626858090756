import { useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import config from "../../config";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  console.log('<========= GuestGuard ================>');
/*
  useEffect(() => {
    if (isAuthenticated && user?.roles?.includes('Admin')) {
      console.log('isAuthenticated -> ', true);
      console.log('isAdmin -> ', user?.roles?.includes('Admin'));
      navigate(config.defaultPath, { replace: true });
    }
  }, [isAuthenticated, navigate, user]);*/

  useEffect(() => {
    if (isAuthenticated) {
      // @ts-ignore
      navigate(location?.state?.from ? location?.state?.from : config.defaultPath, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isAuthenticated, navigate, location, user]);

  return children;
};

export default GuestGuard;
