import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardPage = Loadable(lazy(() => import('pages/dashboard/DashboardPage')));
const BrokersPage = Loadable(lazy(() => import('pages/brokers/BrokersPage')));
const ProfilePage = Loadable(lazy(() => import('pages/profile/ProfilePage')));
const UsersPage = Loadable(lazy(() => import('pages/users/UsersPage')));
const UserProfilePage = Loadable(lazy(() => import('pages/users/UserProfilePage')));
const ProfileViewSection = Loadable(lazy(() => import('sections/profile/ProfileViewSection')));
const UserProfileTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserProfileTabSection')));
const UserAccountsTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserAccountsTabSection')));
const UserCopiersTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserTradeCopierTabSection')));
const UserTradesSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserTradesSection')));
const UserSymbolSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserSymbolSection')));
const UserCopierLogTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserCopierLogTabSection')));
const UserEquityProtectionSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserEquityProtectionSection')));
const UserMapSymbolsPage = Loadable(lazy(() => import('pages/users/UserMapSymbolPage')));
const UserDedicatedEnvTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/UserDedicatedEnvTabSection')));
const DedicatedEnvConfigurationSection = Loadable(
  lazy(() => import('sections/users/user-details-tabs/dedicated-env/DedicatedEnvConfigurationSection'))
);
const DedicatedEnvAccountSection = Loadable(
  lazy(() => import('sections/users/user-details-tabs/dedicated-env/DedicatedEnvAccountSection'))
);

const UserAboutSection = Loadable(lazy(() => import('sections/users/user-details-tabs/profile/UserAboutSection')));
const SubscriptionsTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/profile/SubscriptionsTabSection')));
const PaymentMethodTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/profile/PaymentMethodTabSection')));
const InvoiceTabSection = Loadable(lazy(() => import('sections/users/user-details-tabs/profile/InvoiceTabSection')));

const AccountsPage = Loadable(lazy(() => import('pages/accounts/AccountsPage')));
const AccountsDetailsPage = Loadable(lazy(() => import('pages/accounts/AccountDetailsPage')));
const AccountsEditPage = Loadable(lazy(() => import('pages/accounts/AccountEditPage')));

const ProfileOverviewSection = Loadable(lazy(() => import('sections/accounts/edit/ProfileOverviewSection')));
const AccountManagementSection = Loadable(lazy(() => import('sections/accounts/edit/AccountManagementSection')));
const TradingSymbolSection = Loadable(lazy(() => import('sections/accounts/edit/TradingSymbolsSection')));

const CopyTradingDashboardPage = Loadable(lazy(() => import('pages/copy-trading/CopyTradingDashboardPage')));
const SymbolManagementPage = Loadable(lazy(() => import('pages/symbol/SymbolManagementPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <DashboardPage />
        },
        {
          path: 'brokers',
          element: <BrokersPage />
        },
        {
          path: 'users',
          element: <UsersPage />
        },
        {
          path: 'users/:id',
          element: <UserProfilePage />,
          children: [
            {
              path: '',
              element: <UserProfileTabSection />,
              children: [
                {
                  path: '',
                  element: <UserAboutSection />
                },
                {
                  path: 'overview',
                  element: <UserAboutSection />
                },
                {
                  path: 'subscriptions',
                  element: <SubscriptionsTabSection />
                },
                {
                  path: 'payment-methods',
                  element: <PaymentMethodTabSection />
                },
                {
                  path: 'invoices',
                  element: <InvoiceTabSection />
                }
              ]
            },
            {
              path: 'profile',
              element: <UserProfileTabSection />,
              children: [
                {
                  path: '',
                  element: <UserAboutSection />
                },
                {
                  path: 'overview',
                  element: <UserAboutSection />
                },
                {
                  path: 'subscriptions',
                  element: <SubscriptionsTabSection />
                },
                {
                  path: 'payment-methods',
                  element: <PaymentMethodTabSection />
                },
                {
                  path: 'invoices',
                  element: <InvoiceTabSection />
                }
              ]
            },
            {
              path: 'account',
              element: <UserAccountsTabSection />
            },
            {
              path: 'copiers',
              element: <UserCopiersTabSection />
            },
            {
              path: 'copiers/map-symbols',
              element: <UserMapSymbolsPage />
            },
            {
              path: 'trades',
              element: <UserTradesSection />
            },
            {
              path: 'copier-logs',
              element: <UserCopierLogTabSection />
            },
            {
              path: 'symbols',
              element: <UserSymbolSection />
            },
            {
              path: 'equity-protection',
              element: <UserEquityProtectionSection />
            },
            {
              path: 'dedicated-env',
              element: <UserDedicatedEnvTabSection />,
              children: [
                {
                  path: '',
                  element: <DedicatedEnvConfigurationSection />
                },
                {
                  path: 'env-configuration',
                  element: <DedicatedEnvConfigurationSection />
                },
                {
                  path: 'account-configuration',
                  element: <DedicatedEnvAccountSection />
                }
              ]
            }
          ]
        },
        {
          path: 'profile',
          element: <ProfilePage />,
          children: [
            {
              path: '',
              element: <ProfileViewSection />
            },
            {
              path: 'view',
              element: <ProfileViewSection />
            }
          ]
        },
        {
          path: 'accounts',
          element: <AccountsPage />
        },
        {
          path: 'accounts/view/:id',
          element: <AccountsDetailsPage />
        },
        {
          path: 'accounts/edit/:id',
          element: <AccountsEditPage />,
          children: [
            {
              path: '',
              element: <ProfileOverviewSection />
            },
            {
              path: 'overview',
              element: <ProfileOverviewSection />
            },
            {
              path: 'account-management',
              element: <AccountManagementSection />
            },
            /*{
              path: 'equity-protector',
              element: <EquityProtectorSection />
            },*/
            {
              path: 'trading-symbols',
              element: <TradingSymbolSection />
            }
          ]
        },
        {
          path: 'trade-copiers',
          element: <CopyTradingDashboardPage />
        },
        {
          path: 'symbol-management',
          element: <SymbolManagementPage />
        }
      ]
    }
  ]
};

export default MainRoutes;
